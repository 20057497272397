/**
 * @prettier
 * @flow
 */
import React from 'react';
import CTAButton from '../CTAButton';
import * as UI from '../UI/styles';
import * as S from './styles';

const NewsletterForm = () => (
    <form
        method="POST"
        action="https://blockchaincopywriter.activehosted.com/proc.php"
        id="_form_5CA104087D0C7_"
    >
        <input type="hidden" name="u" value="5CA104087D0C7" data-name="u" />
        <input type="hidden" name="f" value="1" data-name="f" />
        <input type="hidden" name="s" data-name="s" />
        <input type="hidden" name="c" value="0" data-name="c" />
        <input type="hidden" name="m" value="0" data-name="m" />
        <input type="hidden" name="act" value="sub" data-name="act" />
        <input type="hidden" name="v" value="2" data-name="v" />

        <S.TextInput
            type="text"
            name="fullname"
            placeholder="Your full name"
            data-name="fullname"
            required
        />
        <UI.Spacer size="s" />
        <S.TextInput
            type="email"
            name="email"
            placeholder="Your email"
            data-name="email"
            required
        />

        <UI.Spacer size="s" />
        <S.ButtonWrapper>
            <CTAButton id="_form_1_submit" type="submit">
                Subscribe
            </CTAButton>
        </S.ButtonWrapper>
    </form>
);

export default NewsletterForm;
